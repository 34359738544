
















































import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'
import { apiBuilder } from '../../config'

@Component
export default class Register extends Vue {
	private rules = {
		required: (value: string | number) => !!value || 'Không Được Trống.',
		email: (value: string) => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(value) || 'Email Không Hợp Lệ'
		},
	}

	private formData = {
		username: '',
		fullname: '',
		email: '',
		phone: '',
		password: '',
		cfPassword: '',
	}

	private loading = false

	//COMPUTED
	get passwordConfirmationRule() {
		return this.formData.password === this.formData.cfPassword || 'Mật Khẩu Không Trùng'
	}

	//METHOD

	private register() {
		const form = this.$refs.form as Vue & { reset: () => boolean; validate: () => boolean }

		if (form.validate()) {
			this.loading = true

			const apiUrl = apiBuilder('account/signup.php')

			const param = {
				params: {
					username: this.formData.username,
					password: this.formData.password,
					email: this.formData.email,
					name: this.formData.fullname,
					phone: this.formData.phone,
				},
			}

			axios
				.post(apiUrl, null, param)
				.then((res) => {
					// console.log(res.data)

					const error = res.data.error

					if (error == '202') {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: 'Tên Đăng Nhập Đã Tồn Tại !',
						})
					} else if (error == '203') {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: 'Email Đã Tồn Tại !',
						})
					} else if (error == '205') {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: 'Số Điện Thoại Không Hợp Lệ !',
						})
					} else if (error == '206') {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: 'Email Không Hợp Lệ !',
						})
					} else if (error != '201') {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: 'Server Error! Please Reload !',
						})
					}

					form.reset()

					this.loading = false

					this.$swal({
						icon: 'success',
						title: 'Thành Công !',
						text: 'Đăng Ký Thành Công !',
					}).then(() => {
						this.$router.replace({ name: 'Login' })
					})

					setTimeout(() => {
						this.$router.replace({ name: 'Login' })
					}, 1000)
				})
				.catch((e) => {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: e,
					})
				})

			this.loading = false
		}
	}
}
